import {Component, EventEmitter, HostBinding, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {MatInput, MatInputModule} from "@angular/material/input";
import {CodeToFlagEmoji} from "@core/helpers/flags.helpers";
import {MatOptionModule} from "@angular/material/core";
import {NgClass, NgForOf} from "@angular/common";
import {MatAutocompleteModule, MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {Pays} from "@core/backend/models/referentiel";
import {MatFormFieldControl} from '@angular/material/form-field';
import {Subject} from "rxjs";
import {AbstractControlDirective, NgControl} from "@angular/forms";

enum AffichageListe {
    "AffichageTotal",
    "AffichageFiltrer"
}

export type PaysAvecChampsAffichage=Pays&{
  optionSuffixe?: string;
  optionCssSupplementaire?: string;
}

@Component({
  selector: 'core-selection-pays',
  standalone: true,
  imports: [
    MatInputModule,
    CodeToFlagEmoji,
    MatOptionModule,
    NgForOf,
    MatAutocompleteModule,
    NgClass
  ],
  providers: [{provide: MatFormFieldControl, useExisting: SelectionPaysComponent}],
  templateUrl: './selection-pays.component.html',
  styleUrl: './selection-pays.component.css'
})
export class SelectionPaysComponent implements MatFormFieldControl<string>, OnDestroy {

  private _placeholder: string = '';

  @Input()
  set listePaysSelectionnables(value: Array<PaysAvecChampsAffichage>) {
    this._listePaysSelectionnables = value;
    if (this._listePaysSelectionnables != null && this._paysSelectionneISO2 != null) {
      const pays = this._listePaysSelectionnables.find(f => f.iso2 == this._paysSelectionneISO2);
      this.nomPaysAfficheDansInput = pays?.nom ?? '';
    } else {
      this.nomPaysAfficheDansInput = '';
    }
    this.stateChanges.next();
  }

  @Input()
  set paysSelectionneISO2(value: string | null) {
    this._paysSelectionneISO2 = value;
    if (this._listePaysSelectionnables != null && this._paysSelectionneISO2 != null) {
      const pays = this._listePaysSelectionnables.find(f => f.iso2 == this._paysSelectionneISO2);
      this.nomPaysAfficheDansInput = pays?.nom ?? '';
    } else {
      this.nomPaysAfficheDansInput = '';
    }
    this.stateChanges.next();
  }

  get placeholder(): string {
    return this._placeholder;
  }

  @Input()
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _paysSelectionneISO2: string | null = null;
  private _listePaysSelectionnables: Array<PaysAvecChampsAffichage> = [];

  @Output() utilisateurASelectionnePaysISO2 = new EventEmitter<string | null>();
  @Output() utilisateurAInteragis = new EventEmitter<'dirty' | 'touched'>();


  private valeurInitialeALaPriseDeFocus: string | null = null;

  protected nomPaysAfficheDansInput: string | null = null;

  protected etatAffichageListe: AffichageListe = AffichageListe.AffichageTotal;

  protected displayFn : (codePays: string| null) => string;

  constructor() {
    this.displayFn = (codePays: string | null): string => {
      const pays = this._listePaysSelectionnables.find(f => f.iso2 == codePays);
      return pays?.nom ?? '';
    }
  }

  onUtilisateurASelectionneUnPays($event: MatAutocompleteSelectedEvent) {
    const codePaysSelectionne = ($event.option.value as string);

    this.nomPaysAfficheDansInput = this._listePaysSelectionnables.find(f => f.iso2 == codePaysSelectionne)?.nom ?? '';

    this.utilisateurASelectionnePaysISO2.emit(codePaysSelectionne);

    this._paysSelectionneISO2 = codePaysSelectionne;

    this.stateChanges.next();
  }


  onUtilisateurAPrisFocus() {
    this.valeurInitialeALaPriseDeFocus = this.nomPaysAfficheDansInput;
    this.focused = true;
    this.stateChanges.next();
  }

  onUtilisateurABlurFocus() {
    this.focused = false;
    if (this.valeurInitialeALaPriseDeFocus !== this.nomPaysAfficheDansInput) {
      this.etatAffichageListe = AffichageListe.AffichageFiltrer
      const paysSelectionnables = this.getPaysFiltres(this.nomPaysAfficheDansInput);
      if (paysSelectionnables.length == 1) {
        if (paysSelectionnables[0].iso2 !== this._paysSelectionneISO2) {
          this.utilisateurASelectionnePaysISO2.emit(paysSelectionnables[0].iso2);
        }
        this.nomPaysAfficheDansInput = paysSelectionnables[0].nom;
      } else {
        if (!paysSelectionnables.some(ps => ps.nom.toLowerCase() === this.nomPaysAfficheDansInput?.toLowerCase().trim()))
          this.utilisateurASelectionnePaysISO2.emit(null);
      }
    }

    this.utilisateurAInteragis.emit(this.valeurInitialeALaPriseDeFocus !== this.nomPaysAfficheDansInput ? 'dirty' : 'touched');
    this.stateChanges.next();
  }

  @ViewChild('paysInput')
  private paysInput?: MatInput;

  getPaysFiltres(nomPaysAfficheDansInput: string | null) : Array<PaysAvecChampsAffichage> {
    if (this.etatAffichageListe == AffichageListe.AffichageTotal || nomPaysAfficheDansInput == null || nomPaysAfficheDansInput.trim() == '') {
      return this._listePaysSelectionnables;
    }
                                                                          //Décompose la lettre de l'accent et efface l'accent
    return this._listePaysSelectionnables.filter(f => f.nom.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(nomPaysAfficheDansInput!.toLowerCase().trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) !== -1);
  }

  getPremierDrapeauCorrespondantAIso2() {
    const pays = this._listePaysSelectionnables.find(f => f.iso2 == this._paysSelectionneISO2);
    return pays?.iso2 ?? '';
  }

  onUtilisateurAEcritDansInput($event: Event) {
    this.etatAffichageListe = AffichageListe.AffichageFiltrer
    this.nomPaysAfficheDansInput = ($event.target as HTMLInputElement).value;
    this.stateChanges.next();
  }

  onUtilisateuraFocusDansInput() {
    this.etatAffichageListe = AffichageListe.AffichageTotal
  }

  //#region Implémentation de MatFormFieldControl, cf. https://material.angular.io/guide/creating-a-custom-form-field-control

  /**
   * Récupération de la valeur correspondant à l'implémentation de MatFormFieldControl.
   */
  public get value(): string | null {
    return this._paysSelectionneISO2;
  }

  /**
   * Affectation de la valeur correspondant à l'implémentation de MatFormFieldControl.
   * @param value valeur à attribuer au champs
   */
  public set value(value: string | null) {
    this.paysSelectionneISO2 = value;
  }

  /**
   * Sujet qui doit émettre une notification lorsque n'importe laquelle des valeurs dont dépend MatFormfieldcontrol a été modifiée.
   * MatFormFieldControl étant en OnPush, il ne peut pas détecter les changements de valeurs de ses propriétés.
   */
  stateChanges = new Subject<void>();

  static nextId = 0;
  /**
   * Identifiant unique du composant courant pour MatFormFieldControl. Utilisé pour lier les labels et hints.
   */
  @HostBinding() id = `core-selection-pays-${SelectionPaysComponent.nextId++}`;


  /**
   * Permet de lier le composant à un NgControl, pour l'implémentation de ControlValueAccessor.
   * Pas implémenté pour le moment car on n'utilise de toute façon pas les CVA
   * cf. https://material.angular.io/guide/creating-a-custom-form-field-control#ngcontrol )
   */
  ngControl: NgControl | AbstractControlDirective | null = null;

  /**
   * Propriété booléen indiquant si le composant est en focus ou non.
   * Si le composant a le focus, MatFormField ajoute un trait solide en dessous du label.
   */
  focused: boolean = false;

  /**
   * Propriété booléen indiquant si le composant est vide ou non
   */
  get empty(): boolean {
    return this.nomPaysAfficheDansInput == null || this.nomPaysAfficheDansInput == '';
  }

  /**
   * Propriété booléen indiquant si le label doit flotter ou non.
   * Le label doit flotter si le composant est en focus ou si le composant n'est pas vide.
   * cf. https://material.angular.io/guide/creating-a-custom-form-field-control#shouldlabelfloat
   * On en profite pour ajouter une classe sur le composant hôte afin de pouvoir styliser le composant lorsque le label est flottant ou non.
   */
  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  /**
   * Propriété indiquant si le champ est requis ou non
   */
  @Input()
  get required() {
    return this._required;
  }

  set required(req: boolean) {
    this._required = req;
    this.stateChanges.next();
  }

  private _required = false;

  /**
   * Propriété indiquant si le champ est désactivé ou non
   */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
    this.stateChanges.next();
  }

  private _disabled = false;

  /**
   * Propriété permettant d'indiquer si le champ est en erreur ou non, exigée par MatFormFieldControl
   * comme nous utilisons notre propre gestion d'erreur directement sur le model et non sur l champ, on ne considère jamais le champs comme intrinséquemment en erreur.
   */
  errorState: boolean = false;

  /**
   * Type de contrôle
   * Une classe CSS est automatiquement appliquée sur le matformfield
   * Dans notre cas, il devrait s'agir de la classe mat-form-field-type-core-saisie-telephone
   * cf. https://material.angular.io/guide/creating-a-custom-form-field-control#shouldlabelfloat
   */
  controlType: string = 'core-selection-pays';

  /**
   * TODO : décrire ce à quoi sert cette méthode
   * cf. https://material.angular.io/guide/creating-a-custom-form-field-control#setdescribedbyidsids-string
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDescribedByIds(ids: string[]): void {
  }

  /**
   * Méthode appelée à chaque fois que le mat-form-field conteneur est cliqué
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onContainerClick(event: MouseEvent): void {

    if (!this.shouldLabelFloat) {
      this.paysInput!.focus();

    }
  }

  //#endregion

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }
}
