<mat-autocomplete (optionSelected)="onUtilisateurASelectionneUnPays($event)"
                  [displayWith]="displayFn"
                  (closed)="onUtilisateurABlurFocus()"
                  (opened)="onUtilisateurAPrisFocus()"
                  [autoActiveFirstOption]="true" class="choixPaysPanel" #auto="matAutocomplete">
  @for (pays of getPaysFiltres(nomPaysAfficheDansInput); track pays) {
    <mat-option value="{{ pays.iso2 }}"><span
      class="country-flag"
      [ngClass]="pays.optionCssSupplementaire">{{ pays.iso2 | codeToFlagEmoji }}</span> {{ pays.nom }}
      @if (pays.optionSuffixe) {
        <span class="optionSuffixe">{{ pays.optionSuffixe }}</span>
      }

    </mat-option>
  }
</mat-autocomplete>

<span class="country-flag">{{ getPremierDrapeauCorrespondantAIso2()  | codeToFlagEmoji }}</span>

<input type="text"
       matInput
       #paysInput="matInput"
       name="paysNom"
       class="paysInput"
       [value]="nomPaysAfficheDansInput"
       (input)="onUtilisateurAEcritDansInput($event)"
       (focus)="onUtilisateuraFocusDansInput()"
       [matAutocomplete]="auto">


